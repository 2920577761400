import * as React from 'react';
import * as CSS from 'csstype';

const Support = () => {
  return (
    <div>
      <h1>Support</h1>
      <ul>
        <li>
          "Grocerly" is the trademark or registered trademark of Grocerly Limited and/or our
          affiliates in the U.K. and in other countries.
        </li>
      </ul>
      <p>Ou contact email is grocerly@gmail.com</p>
    </div>
  );
};

export default Support;
